.supplier-quotation {
    width: 700;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    font-size: 12px;
  }
  @media print {
    @page {
      size: A4;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 24px;
  }
  
  .date {
    font-weight: bold;
  }
  
  .customer-info {
    margin-bottom: 20px;
  }
  
  .customer-info div {
    margin-bottom: 5px;
  }
  
  .items table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .items table th,
  .items table td {
    border: 1px solid #ddd;
    padding: 5px;
  }
  
  .items table th {
    text-align: center;
  }
  
  .grand-total {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    padding-right: 15px;
  }
  
  .grand-total div {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .in-words {
    margin-top: 20px;
  }
  
  .disable-rounded-total {
    margin-top: 20px;
  }
  
  .disable-rounded-total input {
    margin-right: 5px;
  }


  .print-icon {
    margin-left: 800px;
  }