


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.SelectWithIcon {
  .MuiInputLabel-outlined {
    transform: translate(45px, 20px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(45px, 5px) scale(0.75);
  }
  .MuiSelect-outlined, .MuiInputBase-input {
    padding-left: 2.75rem;
    text-align: left;
  }
}
.layout {
  .MuiTab-root {
    padding: 6px 4px !important;
    @media (min-width:600px) {
       min-width: 20px !important
    }
  }
}
.freebutton .MuiButton-label {
  display: flex;
  min-width: 109px;
  flex-direction: column;
  align-items: 'center';
  justify-content: 'center';
}
a {
  text-decoration: inherit;
  color: inherit;
}
.scroll {
  scroll-snap-type: none;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100vh;
}
.content {
  scroll-snap-align: start;
}

.CuFormInput, .CuFormInput div {
  border-radius: 10px !important;
}




